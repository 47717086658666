import styled from "styled-components";

export const NavbarStyle = styled.div`
  position: relative;
  z-index: 999;
  .navbar input[type="checkbox"],
  .navbar .hamburger-lines {
    display: none;
  }

  .container {
    max-width: 1200px;
    width: 90%;
    margin: auto;
    position: relative;
  }
  .logo-img {
    height: 65px;
  }

  .navbar {
    box-shadow: 0px 5px 10px 0px #aaaaaa54;
    /* position: fixed; */
    width: 100%;
    background: #fff;
    color: #000;
    /* opacity: 0.85; */
    z-index: 100;
    ul {
      margin-bottom: 0;
    }
    h1 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  ul li a {
    transition: 0.5s ease;
    .active {
      font-weight: bold;
    }
  }

  ul:hover li a:not(:hover) {
    color: #000;
    opacity: 0.3;
    filter: blur(1px);
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 64px;
    align-items: center;
  }

  .menu-items {
    order: 2;
    display: flex;
  }
  .logo {
    order: 1;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .menu-items li {
    list-style: none;
    margin-left: 1.5rem;
    font-size: 1.2rem;
  }

  .navbar a {
    color: #444;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease-in-out;
  }

  .navbar a:hover {
    color: var(--color-primary);
  }

  @media (max-width: 768px) {
    .navbar {
      /* opacity: 0.95; */
    }

    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines {
      display: block;
    }

    .navbar-container {
      display: block;
      position: relative;
      height: 64px;
    }

    .navbar-container input[type="checkbox"] {
      position: absolute;
      display: block;
      height: 32px;
      width: 30px;
      top: 20px;
      left: 20px;
      z-index: 5;
      opacity: 0;
      cursor: pointer;
    }

    .navbar-container .hamburger-lines {
      display: block;
      height: 28px;
      width: 35px;
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line {
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: #333;
    }

    .navbar-container .hamburger-lines .line1 {
      transform-origin: 0% 0%;
      transition: transform 0.3s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2 {
      transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3 {
      transform-origin: 0% 100%;
      transition: transform 0.3s ease-in-out;
    }

    .navbar .menu-items {
      padding-top: 100px;
      background: #fff;
      height: 100vh;
      max-width: 300px;
      transform: translate(-150%);
      display: flex;
      flex-direction: column;
      margin-left: -40px;
      padding-left: 40px;
      transition: transform 0.5s ease-in-out;
      box-shadow: 5px 0px 10px 0px #aaa;
      overflow: scroll;
      z-index: 1;
      position: relative;
    }

    .navbar .menu-items li {
      margin-bottom: 1.8rem;
      font-size: 1.1rem;
      font-weight: 500;
    }

    .logo {
      position: absolute;
      top: 10px;
      right: 15px;
      font-size: 1.5rem;
    }

    .navbar-container input[type="checkbox"]:checked ~ .menu-items {
      transform: translateX(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
      transform: rotate(45deg);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
      transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
      transform: rotate(-45deg);
    }
  }

  @media (max-width: 500px) {
    .navbar-container input[type="checkbox"]:checked ~ .logo {
      display: none;
    }
    .logo-img {
      height: 65px;
      position: absolute;
      top: 1px;
      right: 10px;
    }
  }
`;

export const SliderStyle = styled.div`
  a {
    text-decoration: none;
    color: var(--clr-secondary);
  }
  .btn-link {
    font-weight: 400;
  }
  .slider-container {
    height: 95vh;
    width: 100%;
    position: relative;
    margin: auto;
    overflow: hidden;
  }

  .active {
    display: inline-block;
  }

  .inactive {
    display: none;
  }

  .slides {
    height: 95vh;
    width: 100%;
    position: relative;
  }

  .slide-image {
    width: 100%;
    height: 100vh;
    position: absolute;
    object-fit: cover;
  }

  .slide-title,
  .slide-text {
    width: 60%;
    height: 100%;
    color: white;
    font-size: 50px;
    position: absolute;
    text-align: left;
    padding-left: 7rem;
    top: 40%;
    z-index: 10;
  }
  button {
    z-index: 10;
    top: 70%;
  }
  /* .btn {
    top: 60%;
    left: 30%;
    z-index: 10;
  } */
  .btn--primary--solid {
    background-color: var(--clr-white);
    color: var(--clr-primary);
    border: none;
  }
  .btn--medium {
    padding: 0.8rem 1.8rem;
    font-size: 1.2rem;
  }

  .slide-title {
    font-weight: 400;
    font-size: 2.8rem;
  }

  .slide-text {
    top: 52%;
    font-size: 1.6rem;
    font-weight: 300;
  }

  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 0.418);
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
  }

  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }

  .next {
    right: 0;
    border-radius: 5px 0px 0px 5px;
  }

  .all-dots {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 95%;
    justify-content: center;
    z-index: 200;
  }

  .dot {
    cursor: pointer;
    height: 0.8rem;
    width: 0.8rem;
    margin: 0px 5px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: inline-block;
  }

  .active-dot,
  .dot:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  @media screen and (max-width: 600px) {
    .slide-title,
    .slide-text {
      width: 100%;
      height: 100%;
      color: white;
      font-size: 50px;
      position: absolute;
      text-align: center;
      padding-left: 1px;
      top: 30%;
      z-index: 10;
    }

    .slide-title {
      font-size: 1.8;
    }

    .slide-text {
      top: 50%;
      font-size: 1.4rem;
      padding: 0 1rem;
    }
  }
`;

export const HeroSectionStyle = styled.div`
  background: lightgray;
  width: 100%;
  height: 100%;
  min-height: 70vh;
  display: grid;
  grid-template-columns: 2fr 2fr;
  place-items: center;
  z-index: 0;

  img {
    height: 60vh;
    width: auto;
    object-fit: contain;
    margin: auto;
  }

  .right-text {
    display: grid;
    place-items: center;
    height: 100%;
    max-width: 500px;
    text-align: center;
    .title {
      line-height: 90px;
      display: block;
      font-size: 5rem;
      font-weight: 600;
      background-image: var(--grd-hero-title);
    }

    .sub-title {
      line-height: 80px;
      font-size: 5.3rem;
      font-weight: 700;
      background-image: var(--grd-hero-sub-title);
    }

    .text {
      margin: 1rem 0;
      font-weight: 900;
      font-size: 1rem;
      line-height: 23px;
      text-align: center;
    }

    button {
      padding: 0.6rem 2.4rem;
      background: var(--grd-hero-buy-btn-bg);
      color: white;
      font-size: 2rem;
      font-weight: 600;
      border-radius: 40px;
      border-color: transparent;
      margin: 2rem;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    height: fit-content;
    width: 100%;

    .right-text {
      max-width: 100%;
      padding: 0 2rem;
    }
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    height: fit-content;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;
    height: 90vh;

    img {
      width: 60%;
      height: auto;
    }

    .right-text {
      max-width: 100%;
      padding: 0 1rem;

      .title {
        font-size: 2rem;
        line-height: normal;
      }

      .sub-title {
        font-size: 3rem;
        line-height: normal;
      }
    }
    button {
      margin: 0 !important;
      font-size: 1.5rem !important;
      padding: 0.3rem 1.5rem !important;
    }
  }
`;

export const CalenderSectionStyle = styled.div`
  .event-container {
    font-family: "Roboto", sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
  .eve-container {
  }
  .row {
    align-items: center;
    justify-content: center;
    margin: 0 2rem;
  }
  .event-description {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .agenda-icon {
    color: var(--clr-primary);
    font-size: 1.2rem;
    margin-right: 0.2rem;
  }
  .event-time-icon {
    color: var(--clr-primary);
    font-size: 1.2rem;
    margin-right: 0.2rem;
  }
  .event-container h3.year {
    font-size: 40px;
    text-align: center;
    border-bottom: 1px solid #b1b1b1;
  }
  .no-event-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .event-container .event {
    box-shadow: 0 4px 16px -8px rgba(0, 0, 0, 0.4);
    display: flex;
    border-radius: 8px;
    margin: 32px 0;
  }

  .event .event-left {
    background: #09222d;
    min-width: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #eee;
    padding: 8px 48px;
    font-weight: bold;
    text-align: center;
    border-radius: 8px 0 0 8px;
  }

  .event .event-left .date {
    font-size: 56px;
  }

  .event .event-left .month {
    font-size: 16px;
    font-weight: normal;
  }

  .event .event-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px;
  }

  .event .event-right h3.event-title {
    font-size: 20px;
    margin: 24px 0 10px 0;
    color: var(--clr-primary);
    text-transform: uppercase;
  }

  .event .event-right .event-timing {
    background: #f1fbff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    padding: 8px;
    border-radius: 16px;
    margin: 24px 0;
    font-size: 14px;
  }

  .event .event-right .event-timing img {
    height: 20px;
    padding-right: 8px;
  }

  @media (max-width: 550px) {
    .event-container {
      display: block;
      margin: 0 2rem;
    }
    .event {
      flex-direction: column;
    }

    .event .event-left {
      padding: 0;
      border-radius: 8px 8px 0 0;
    }

    .event .event-left .event-date .date,
    .event .event-left .event-date .month {
      display: inline-block;
      font-size: 24px;
    }

    .event .event-left .event-date {
      padding: 10px 0;
    }
  } /*  */
  @media (max-width: 750px) {
    .event-container {
      display: block;
      margin: 0 2rem;
    }
  }
`;

export const MemberSectionStyle = styled.div`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body {
    background: #fefefe;
  }

  .container {
    width: 90%;
    margin: 50px auto;
  }
  .heading {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 50px;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-flow: wrap;
  }
  .main-form {
    display: flex;
    flex-direction: column;
  }
  .card {
    width: 24%;
    background: #fff;
    border: 1px solid #ccc;
    margin-bottom: 50px;
    transition: 0.3s;
    text-align: center;
  }
  .card-header {
    text-align: center;
    background: linear-gradient(to right, #ff416c, #ff4b2b);
    color: #fff;
  }
  .card img {
    width: 60%;
    height: 200px;
    margin-top: 5%;
    border-radius: 60%;
    object-fit: cover;
  }
  .card-body {
    padding: 10px 20px;
    text-align: center;
    font-size: 18px;
  }
  .card-body p {
    font-size: 0.9rem;
    font-weight: 300;
  }
  .card-body h4 {
    font-weight: 400;
  }
  .card-body b {
    margin-bottom: 3%;
  }
  .card-body .btn {
    display: block;
    color: #fff;
    text-align: center;
    background: #0082e6;
    margin-top: 20px;
    text-decoration: none;
    padding: 10px 5px;
  }
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.25);
  }
  @media screen and (max-width: 1000px) {
    .card {
      width: 40%;
    }
  }
  @media screen and (max-width: 620px) {
    .container {
      width: 100%;
    }
    .heading {
      padding: 20px;
      font-size: 20px;
    }
    .card {
      width: 80%;
    }
  }
`;

export const SearchStyle = styled.div`
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 0.6rem;
  }
  .search-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .search-box {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search-input {
    width: 100%;
    padding: 10px;
    border: 2px solid var(--clr-primary);
    border-radius: 10px 0 0 10px;
    border-right: none;
    outline: none;
    font-size: 20px;
    color: tomato;
    background: none;
  }
  .search-button {
    text-align: center;
    height: 47px;
    width: 40px;
    outline: none;
    cursor: pointer;
    border: 2px solid var(--clr-primary);
    border-radius: 0 10px 10px 0;
    border-left: none;
    background: none;
    font-size: 20px;
    border-left: 1px solid var(--clr-primary);
  }
  .search-icon {
    color: var(--clr-primary);
  }
  .container {
    width: 50%;
    position: absolute;
    /* left: 48%; */
    top: 45%;
    transform: translate(-90%, -50%);
  }
`;

export const FooterStyle = styled.div`
  .footer-distributed {
    background: #09222d;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 55px 120px;
  }
  .footer-bottom {
    background: #102832;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    padding: 0.5rem;
    ul {
      display: flex;
      gap: 1rem;
    }
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
  }

  /* Footer left */

  .footer-distributed .footer-left {
    width: 40%;
  }

  /* The company logo */

  .footer-distributed h3 {
    color: #ffffff;
    font: normal 36px "Open Sans", cursive;
    margin: 0;
  }

  .footer-distributed h3 span {
    color: lightseagreen;
  }

  /* Footer links */

  .footer-distributed .footer-links {
    color: #ffffff;
    margin: 20px 0 12px;
    padding: 0;
  }

  .footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    font-weight: 400;
    text-decoration: none;
    color: inherit;
  }

  .footer-distributed .footer-company-name {
    color: #e1e1e1;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }

  /* Footer Center */

  .footer-distributed .footer-center {
    width: 35%;
  }
  .footer-distributed .footer-center-contact {
    font-size: 1.2rem;
    color: #fff;
  }
  .footer-distributed .footer-center .footer-contact {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
  }
  .footer-center .footer-contact .footer-conatct-icons {
    font-size: 1rem;
    color: #f1f1f1;
    margin-right: 0.5rem;
  }

  .footer-distributed .footer-center i {
    background-color: #33383b;
    color: #ffffff;
    font-size: 25px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
  }

  .footer-distributed .footer-center i.fa-envelope {
    font-size: 17px;
    line-height: 38px;
  }

  .footer-distributed .footer-center p {
    display: inline-block;
    color: #ffffff;
    font-weight: 400;
    vertical-align: middle;
    margin: 0;
  }

  .footer-distributed .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
  }

  .footer-distributed .footer-center p a {
    color: lightseagreen;
    text-decoration: none;
  }

  .footer-distributed .footer-links a:before {
    content: "|";
    font-weight: 300;
    font-size: 20px;
    left: 0;
    color: #fff;
    display: inline-block;
    padding: 0 5px;
  }

  .footer-distributed .footer-links .link-1:before {
    content: none;
  }

  /* Footer Right */

  .footer-distributed .footer-right {
    width: 20%;
  }

  .footer-distributed .footer-company-about {
    line-height: 20px;
    color: #92999f;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
  }

  .footer-distributed .footer-company-about span {
    display: block;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .footer-distributed .footer-icons {
    margin-top: 25px;
  }

  .footer-distributed .footer-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #33383b;
    border-radius: 2px;

    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;

    margin-right: 3px;
    margin-bottom: 5px;
  }
  .footer-bottom{
  display: grid;
  place-items: center;
  ul li {
    list-style: none;
  }
}
  /* If you don't want the footer to be responsive, remove these media queries */
@media screen and (max-width: 500px) {
  .footer-distributed .footer-center {
    display: grid;
    place-items: center;
    padding: 0 1rem;
  width: 100%;
}
.footer-bottom{
  display: grid;
  place-items: center;
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
}
}
  @media (max-width: 880px) {
    .footer-distributed {
      font: bold 14px sans-serif;

      padding: 55px 10px;
    }

    .footer-distributed .footer-left,
    .footer-distributed,
    .footer-distributed .footer-right {
      display: block;
      width: 100%;
      text-align: center;
    }

    .footer-distributed .footer-center i {
      margin-left: 0;
     
    }
    .footer-center {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-bottom: 3rem;
    }
    .footer-distributed .footer-center .footer-contact {
      display: flex;
      align-items: center;
      padding-bottom: 0.5rem;
    }
    .footer-distributed .footer-center p {
      text-align: left;
    }
    .footer-left img {
      text-align: center;
    }
  }
`;

export const EventDetailSectionStyle = styled.div`
  .image {
    width: 100%;
    margin: 0% auto;
  }
  .image img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    margin: auto;
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 5%;
    width: 40%;
    text-align: center;
  }
  .text p {
    background-color: #000000d1;
    color: white;
    padding: 6%;
    margin: 6%;
    font-size: 1.4rem;
  }

  @media (max-width: 900px) {
    .image img {
      height: 50vh;
    }
    .text p {
      font-size: 1.2rem;
    }
  }
  .content {
    text-align: center;
    width: 70%;
    margin: 5% auto;
    border: 1px solid #0000002b;
  }
  .content h2 {
    padding-top: 2%;
    padding-bottom: 1%;
    font-weight: 500;
    color: #000000cf;
  }
  .content p {
    padding-bottom: 3%;
    padding-left: 5%;
    padding-right: 5%;
    font-weight: 300;
    font-size: 1.1rem;
  }
  @media (max-width: 600px) {
    .image img {
      height: 46vh;
    }
    .content {
      width: 90%;
    }
    .image {
      width: 100%;
    }
    .text p {
      font-size: 1rem;
    }
  }
`;

export const AboutSectionStyle = styled.div`
  .about-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #f1f1f1;
  }

  .about-section {
    background: url(${process.env.PUBLIC_URL}/images/about-img.jpg) no-repeat
      left;
    background-size: 55%;
    background-color: #f1f1f1;
    overflow: hidden;
    padding: 150px 0 20px 0;
    margin: 3rem 8rem 4rem;
  }

  .inner-container {
    width: 55%;
    float: right;
    background-color: #fdfdfd;
    padding: 80px;
  }

  .about-section-nxt {
    background-size: 100%;
    background-color: #f1f1f1;
    overflow: hidden;
    padding: 10px 0;
    margin: 0 8rem;
  }
  .inner-container-nxt {
    width: 100%;
    float: right;
    background-color: #fdfdfd;
    padding: 50px;
  }

  .inner-container h1 {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 900;
  }

  .text {
    color: #545454;
    line-height: 25px;
    text-align: justify;
    margin-bottom: 10px;
  }

  .skills {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 13px;
  }

  @media screen and (max-width: 1200px) {
    .inner-container {
      padding: 80px;
    }
  }

  @media screen and (max-width: 1000px) {
    .about-section {
      background-size: 100%;
      padding: 100px 40px;
    }
    .inner-container {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    .about-section {
      padding: 0;
    }
    .inner-container {
      padding: 60px;
    }
  }
`;

export const ContactSectionStyle = styled.div`
  background-color: #f1f1f1;
  padding: 50px 0px;
  .right_conatct_social_icon {
    background: linear-gradient(to top right, #0099d9 -5%, #57ba52 100%);
  }
  .contact_us {
    background-color: #f1f1f1;
    padding: 10px 0px;
    margin: 2rem 20rem;
  }

  .contact_inner {
    background-color: #fff;
    position: relative;
    box-shadow: 20px 22px 44px #cccc;
    border-radius: 25px;
  }
  .contact_form_inner-new {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact_field {
    padding: 60px 240px 90px 100px;
  }
  .contact_field-new {
    padding: 60px 90px 90px 90px;
  }
  .right_conatct_social_icon {
    height: 100%;
  }
  .forminput {
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .forminput span {
    font-size: 0.8rem;
    color: red;
  }
  .alert {
    color: green;
    background: #eaeaea;
    text-align: center;
    padding: 0.5rem 0;
    font-weight: 500;
    border-radius: 0.5rem;
  }
  .contact_field h3 {
    color: #000;
    font-size: 40px;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .contact_field p {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 35px;
  }
  .contact_field .form-control {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #ccc;
    width: 100%;
  }
  .contact_field .form-control:focus {
    box-shadow: none;
    outline: none;
    border-bottom: 2px solid #1325e8;
  }
  .contact_field .form-control::placeholder {
    font-size: 1rem;
    letter-spacing: 1px;
  }
  .contact_field-new h3 {
    color: #000;
    font-size: 40px;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .contact_field-new p {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 35px;
  }
  .contact_field-new .form-control {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #ccc;
    width: 100%;
  }
  .contact_field-new .form-control:focus {
    box-shadow: none;
    outline: none;
    border-bottom: 2px solid #1325e8;
  }
  .contact_field-new .form-control::placeholder {
    font-size: 1rem;
    letter-spacing: 1px;
  }
  .contact-field {
    display: flex;
    padding: 0.7rem 0;
  }
  .contact-field input {
    margin: 0.5rem;
    width: 100%;
    font-size: 1rem;
  }
  .contact-field textarea {
    margin: 0.5rem;
    width: 100%;
    font-size: 1rem;
  }

  .contact_info_sec {
    position: absolute;
    background-color: #2d2d2d;
    right: 1px;
    top: 18%;
    height: 340px;
    width: 380px;
    padding: 30px;
    border-radius: 25px 0 0 25px;
    color: #fff;
  }
  .contact_info_sec h4 {
    letter-spacing: 1px;
    padding-bottom: 15px;
  }

  .contact-icons {
    font-size: 1.5rem;
    margin: 0.5rem;
  }

  .info_single {
    margin: 30px 0px;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    justify-content: left;
  }
  .info_single i {
    margin-right: 15px;
  }
  .info_single span {
    font-size: 14px;
    letter-spacing: 1px;
    margin-left: 2rem;
  }

  button.contact_form_submit {
    background: linear-gradient(to top right, #57ba52 -5%, #0099d9 100%);
    border: none;
    color: #fff;
    padding: 10px 15px;
    width: 100%;
    margin-top: 25px;
    border-radius: 35px;
    cursor: pointer;
    font-size: 1.2rem;
    letter-spacing: 2px;
    font-weight: 600;
  }
  .socil_item_inner li {
    list-style: none;
  }
  .socil_item_inner li a {
    color: #fff;
    margin: 0px 15px;
    font-size: 14px;
  }
  .socil_item_inner {
    padding-bottom: 10px;
  }

  .footer-center {
    padding-bottom: 1rem;
  }

  .contact-row {
    display: grid;
    grid-template-columns: 3fr 1fr;
  }
  .contact-row-new {
    display: grid;
    grid-template-columns: 4fr;
  }
  form {
    width: 90%;
  }

  @media screen and (max-width: 1050px) {
    .contact-row {
      display: block;
      grid-template-columns: 1fr;
    }
    .contact_us {
      padding: 10px 0px;
      margin: 2rem 5rem;
    }
    .contact-form-fields {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .contact-field input {
      margin-bottom: 1rem;
    }
    .contact-field {
      padding: 20px 60px 20px 60px;
    }
    .contact_field-new {
      padding: 10px 10px 50px 10px;
    }
    button.contact_form_submit {
      padding: 10px 25px;
      margin-top: 25px;
      text-align: center;
    }
    .contact_info_sec {
      position: relative;
      border-radius: 25px 25px 25px 25px;
      margin: -2rem 3rem 4rem 3rem;
      right: 1px;
      top: 18%;
      height: auto;
      width: 90%;
      padding: 30px;
    }
  }

  @media screen and (max-width: 480px) {
    .contact-row {
      display: block;
      grid-template-columns: 1fr;
    }
    .contact_us {
      padding: 10px 0px;
      margin: 2rem 1rem;
    }
    .contact_inner {
      position: relative;
      box-shadow: 20px 22px 44px #cccc;
      border-radius: 25px;
    }

    .contact_field {
      display: block;
      padding: 60px 40px 90px 40px;
    }
    .contact-field input {
      margin-bottom: 1rem;
    }
    .contact-field {
      padding: 0;
    }
    button.contact_form_submit {
      padding: 10px 25px;
      margin-top: 25px;
      text-align: center;
    }
    .contact_info_sec {
      position: relative;
      border-radius: 25px 25px 25px 25px;
      margin: -2rem 3rem 4rem 3rem;
      right: 1px;
      top: 18%;
      height: 350px;
      width: 355px;
      padding: 30px;
    }
  }
`;

export const StyledSelect = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  option {
    padding: 10px;
  }
`;

export const MemberSignupStyle = styled.div`
  .form-errors {
    color: #ed3338;
  }
  .container {
    position: relative;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 5% auto;
  }
  p {
    margin: 1em 0.5em 0 0;
    line-height: 1.4285em;
  }
  .container .forms {
    width: 200%;
    display: flex;
    align-items: center;
    transition: height 0.4s ease;
  }

  .container .form {
    width: 50%;
    padding: 30px;
    border-radius: 12px;
    background: #fff;
    transition: margin-left 0.36s ease;
  }

  .container.active .login {
    margin-left: -50%;
    opacity: 0;
    transition: margin-left 0.36s ease, opacity 0.3s ease;
  }

  .container.active .login {
    opacity: 0;
    transition: margin-left 0.36s ease, opacity 0.3s ease;
  }

  .container .signup {
    opacity: 0;
    transition: opacity 0.18s ease;
  }

  .container.active .signup {
    opacity: 1;
    transition: opacity 0.4s ease;
  }

  .container.active .forms {
    height: 600px;
  }

  .container .form .title {
    position: relative;
    font-size: 27px;
    font-weight: 600;
  }

  .form .title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    background-color: var(--clr-primary);
    border-radius: 25px;
  }

  .form .input-field {
    position: relative;
    height: 50px;
    width: 100%;
    margin-top: 30px;
  }

  .input-field input {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0 35px;
    border: none;
    outline: none;
    font-size: 16px;
    border-bottom: 2px solid #ccc;
    border-top: 2px solid transparent;
    transition: border-bottom-color 0.4s ease;
  }

  .input-field input:is(:focus, :valid) {
    border-bottom-color: var(--clr-primary);
  }

  .input-field i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 23px;
    transition: color 0.4s ease;
  }

  .input-field input:is(:focus, :valid) ~ i {
    color: var(--clr-primary);
  }

  .input-field i.icon {
    left: 0;
  }

  .input-field i.showHidePw {
    right: 0;
    cursor: pointer;
    padding: 10px;
  }

  .form .checkbox-text {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .checkbox-text .checkbox-content {
    display: flex;
    align-items: center;
  }

  .checkbox-content input {
    margin: 0 8px -2px 4px;
    accent-color: var(--clr-primary);
  }

  .form .text {
    color: #333;
    font-size: 14px;
  }

  .form a.text {
    color: var(--clr-primary);
    text-decoration: none;
  }

  .form a {
    text-decoration: none;
  }

  .form a:hover {
    text-decoration: underline;
  }

  .form .button {
    margin-top: 35px;
  }

  .form .button input {
    border: none;
    color: white;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 6px;
    background-color: var(--clr-primary);
    cursor: pointer;
    transition: all 0.6s ease;
  }

  .button input:hover {
    background-color: var(--clr-fourth);
  }

  .form .login-signup {
    margin-top: 30px;
    text-align: center;
  }
`;

export const MemberDetailSectionStyle = styled.div`
  height: 80vh;
  background-color: rgb(243, 242, 245);

  /* Paragraphs */

  /* #Links */
  a {
    color: rgb(var(--primary-dark));
    font-size: var(--paragraph);
    text-decoration: underline;
  }
  a:visited {
    color: rgba(var(--primary-dark), 0.5);
  }
  /* #Buttons */
  button {
    padding: 0.6em 1em;
    border-radius: 0.25rem;
    border: 1px solid rgb(184, 184, 184);
    background-color: rgb(243, 242, 245);
    font-size: calc(1.11rem - 0.2rem);
    outline: none;
  }
  button:focus {
    box-shadow: 0 0 0 2px #000, 0 0 0 3px var(--white);
    border: 1px solid transparent;
  }
  /* #Lists */
  ul,
  ol {
    margin: 1em 0;
  }
  /* #Forms */

  /* #Cursor Pointer */
  button,
  a {
    cursor: pointer;
  }
  /* = - = - = - = - = -
    Global Classes
- = - = - = - = - = - */
  .space {
    height: 50vh;
  }
  /* #Flexbox */
  .flexbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flexbox-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .flexbox-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .flexbox-start {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  /* Columns */
  .flexbox-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .flexbox-col-left {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
  .flexbox-col-right {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }
  .flexbox-col-start {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
  .flexbox-col-start-center {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  /* Spacings */
  .flexbox-space-bet {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flexbox-space-bet-start {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  /* = - = - = -
    Classes
- = - = - = - */
  [class^="material-icons-round"] {
    user-select: none;
  }
  .view-width {
    width: 60%;
  }
  /* = - = - = -
    Navbar
- = - = - = - */
  /*  */
  /* = - = - = - = -
    Profile Top
- = - = - = - = - */
  .profile-info {
    width: 100%;
  }
  .profile-info-inner {
    margin: 2.5em 0;
  }
  /* Profile Picture */
  .profile-picture-large {
    padding: 0.3em;
    width: 10em;
    height: 10em;
    margin: 0 2.5em 0 0;
  }
  .profile-picture-large .profile-picture-inner {
    width: 100%;
    height: 100%;
    border: 0.3em solid rgb(243, 242, 245);
  }
  .profile-picture-small {
    padding: 0.2em;
    width: 4em;
    height: 4em;
    margin: 0 0.75em 0 0;
  }
  .profile-picture-small .profile-picture-inner {
    width: 100%;
    height: 100%;
    border: 0.2em solid rgb(243, 242, 245);
  }
  .profile-picture-wrapper {
    position: relative;
    cursor: pointer;
  }
  .profile-picture-inner {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    z-index: 2;
  }
  .profile-picture {
    min-width: 100%;
    max-width: 110%;
    min-height: 100%;
    display: block;
    position: absolute;
    object-fit: cover;
    z-index: 2;
  }
  .profile-picture-background {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(
      121deg,
      rgb(121, 134, 203) 0%,
      rgb(225, 190, 231) 100%
    );
    border-radius: 50%;
    z-index: 1;
  }
  .profile-picture-note {
    top: 2.25em;
    left: 1.75em;
    padding: 0.75em 1.25em;
    position: fixed;
    will-change: transform;
    color: var(--white);
    background-color: var(--black);
    border-radius: 0.25rem;
    pointer-events: none;
    transition: opacity 0.15s ease-in-out;
    opacity: 0;
    z-index: 4;
  }
  /* Username */
  .profile-username .name {
    margin: 0 0.2em 0 0;
  }
  .profile-username .material-icons-round {
    color: rgb(var(--blue));
    font-size: var(--heading-small);
  }
  .profile-username-small .name {
    margin: 0 0.25em 0 0;
    font-size: calc(1.11rem + 0.25rem);
  }
  .profile-username-small .material-icons-round {
    color: rgb(var(--blue));
    font-size: calc(1.11rem + 0.25rem);
  }
  /* Profile Followers */
  .profile-followers p {
    margin: 0 1.5em 0 0;
    cursor: pointer;
  }
  .profile-followers p span {
    margin: 0 0.25em 0 0;
    font-weight: 700;
  }
  /* Profile Bio */
  .profile-bio {
    margin: 1.5em 0 0 0;
    font-weight: 300;
  }
  .profile-bio-inner .line {
    display: block;
    word-break: break-word;
  }
  .profile-bio-link-inner {
    color: var(--black);
    text-decoration: none;
  }
  .profile-bio-link-inner:visited {
    color: var(--black);
  }
  .profile-bio-link {
    font-weight: 500;
    color: rgba(var(--blue), 1);
    text-decoration: none;
  }
  .profile-bio-link:visited {
    color: rgba(var(--blue), 0.5);
    text-decoration: none;
  }
  /* = - = - = - = - = -
    Profile Stories
- = - = - = - = - = -*/
  .profile-stories {
    width: 100%;
  }
  .profile-stories-inner {
    margin: 2.5em 0;
    position: relative;
    overflow: hidden;
    user-select: none;
    cursor: grab;
  }
  .profile-stories-inner:active {
    cursor: grabbing;
  }
  .profile-stories-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(
      -90deg,
      rgba(var(--background), 1) 0%,
      rgba(var(--background), 0) 50%,
      rgba(var(--background), 0) 100%
    );
    pointer-events: none;
    z-index: 100;
  }
  .profile-story-wrapper {
    margin: 0 2.5em 0 0;
    padding: 0.3em;
    width: 8em;
    height: 8em;
    position: relative;
  }
  .profile-story-inner {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 50%;
    border: 0.3em solid rgb(var(--background));
    overflow: hidden;
    z-index: 2;
  }
  .profile-story {
    min-width: 100%;
    max-width: 110%;
    min-height: 100%;
    display: block;
    position: absolute;
    object-fit: cover;
    z-index: 2;
  }
  .profile-story-background {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(
      121deg,
      rgb(var(--accent)) 0%,
      rgb(var(--primary)) 100%
    );
    border-radius: 50%;
    z-index: 1;
  }
  .profile-story-background-gray {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(var(--gray), 0.4);
    border-radius: 50%;
    z-index: 1;
  }

  /* -
-*-~*~-*-*-~*~-*-*-~*~-* |
　　　　@media rules　　　　|
-*-~*~-*-*-~*~-*-*-~*~-* |
- */
  @media only screen and (max-width: 1660px) {
    .view-width {
      width: 65%;
    }
  }
  @media only screen and (max-width: 1456px) {
    .view-width {
      width: 70%;
    }
  }
  @media only screen and (max-width: 1220px) {
    .view-width {
      width: 75%;
    }
    /* = - = - = - = -
        Profile Top
    - = - = - = - = - */
    /* Profile Picture */
    .profile-picture-large {
      padding: 0.3em;
      width: 8em;
      height: 8em;
      margin: 0 1.5em 0 0;
    }
    .profile-picture-large .profile-picture-inner {
      width: 100%;
      height: 100%;
      border: 0.3em solid rgb(var(--background));
    }
  }
  @media only screen and (max-width: 1024px) {
    :root {
      /* Sizes */
      --heading-large: 5.2rem;
      --heading-medium: 3.2rem;
      --heading-small: 2rem;
      --paragraph: 1rem;
    }
    .view-width {
      width: 85%;
    }
    /* = - = - = - = -
        Profile Top
    - = - = - = - = - */
    /* Profile Picture */
    .profile-picture-large {
      padding: 0.2em;
      width: 6em;
      height: 6em;
      margin: 0 1.25em 0 0;
    }
    .profile-picture-large .profile-picture-inner {
      width: 100%;
      height: 100%;
      border: 0.2em solid rgb(var(--background));
    }
    /* Profile Story */
    .profile-stories-inner {
      margin: 1.5em 0;
    }
    .profile-story-wrapper {
      margin: 0 1.5em 0 0;
      padding: 0.2em;
      width: 6em;
      height: 6em;
    }
    .profile-story-inner {
      width: 100%;
      height: 100%;
      border: 0.2em solid rgb(var(--background));
    }
    /* = - = - = - = - =
        Profile Grid
  
}
@media only screen and (max-width: 756px) {
    .view-width {
        width: calc(100% - 5rem);
    }
    /* = - = - = - = -
        Profile Top
    - = - = - = - = - */
    .profile-info-inner {
      flex-direction: column;
    }
    .profile-left {
      margin: 0 0 1em 0;
      flex-direction: column;
    }
    /* Profile Picture */
    .profile-picture-large {
      margin: 0 0 1em 0;
    }
    /* = - = - = - = - =
        Profile Grid
 
}
@media only screen and (max-width: 576px) {
    /* = - = - = - = - =
        Profile Grid
    - = - = - = - = - = */
  }
  @media only screen and (max-width: 496px) {
  }
`;

export const CertificateSectionStyle = styled.div`
  width: 950px;
  .cet-container {
    width: 950px;
  }

  .certificate-container {
    padding: 10px;
    width: 940px;
  }
  .certificate {
    border: 20px solid var(--clr-primary);
    padding: 25px;
    height: 600px;
    position: relative;
  }
  .accr {
    position: fixed;
    right: 40px;
    left: 40px;
    bottom: 100px;
  }
  .accr-row-sec-names {
    align-self: end;
  }
  .certificate-no {
    position: absolute;
    right: 10px;
    top: 5px;
  }
  .certificate:after {
    content: "";
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    position: absolute;
    background-image: url(${process.env.PUBLIC_URL}/images/SiaWatermarks.png);
    background-size: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .certificate-header > .logo {
    width: 130px;
    height: 70px;
  }

  .certificate-title {
    text-align: center;
  }

  .certificate-body {
    text-align: center;
  }

  h1 {
    font-weight: 400;
    font-size: 48px;
    color: var(--clr-primary);
  }

  .student-name {
    font-size: 24px;
  }

  .certificate-content {
    margin: 0 auto;
    width: 750px;
  }

  .about-certificate {
    width: 380px;
    margin: 0 auto;
  }

  .topic-description {
    text-align: center;
    font-size: 16px;
    line-height: 1.8;
  }
  .accr-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 80px;
  }
  .accr-row-sec {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
  }
  .accr-row-sec-names p {
    line-height: 32px; /* within paragraph */
    margin-bottom: 2px;
  }

  .print-btn {
    text-align: center;
    padding-bottom: 5px;
  }

  button {
    background: transparent;
  }

  .button button {
    text-decoration: none;
    font-size: 1rem;
    padding: 1% 2%;
    color: white;
    background-color: #0082e6;
    border: none;
    border-radius: 5%;
  }
  .button a {
    color: white;
    text-decoration: none;
  }

  @media (max-width: 800px) {
    .container {
      width: 90%;
    }
    .button button {
      padding: 2% 4%;
    }
  }
  @media print {
    body * {
      visibility: hidden;
    }
    .container {
      visibility: visible;
    }
    .button {
      visibility: hidden;
    }
  }
`;

export const LoginSectionStyle = styled.div`
  .container {
    position: relative;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 5% auto;
  }

  .container .forms {
    width: 200%;
    display: flex;
    height: 400px;

    align-items: center;
    transition: height 0.4s ease;
  }

  .container .form {
    width: 50%;
    padding: 30px;
    border-radius: 12px;
    background: #fff;
    transition: margin-left 0.36s ease;
  }

  .container.active .login {
    margin-left: -50%;
    opacity: 0;
    transition: margin-left 0.36s ease, opacity 0.3s ease;
  }

  .container.active .login {
    opacity: 0;
    transition: margin-left 0.36s ease, opacity 0.3s ease;
  }

  .container .signup {
    opacity: 0;
    transition: opacity 0.18s ease;
  }

  .container.active .signup {
    opacity: 1;
    transition: opacity 0.4s ease;
  }

  .container.active .forms {
    height: 600px;
  }

  .container .form .title {
    position: relative;
    font-size: 27px;
    font-weight: 600;
  }

  .form .title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    background-color: var(--clr-primary);
    border-radius: 25px;
  }

  .form .input-field {
    position: relative;
    height: 50px;
    width: 100%;
    margin-top: 30px;
  }

  .input-field input {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0 35px;
    border: none;
    outline: none;
    font-size: 16px;
    border-bottom: 2px solid #ccc;
    border-top: 2px solid transparent;
    transition: border-bottom-color 0.4s ease;
  }

  .input-field input:is(:focus, :valid) {
    border-bottom-color: var(--clr-primary);
  }

  .input-field i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 23px;
    transition: color 0.4s ease;
  }

  .input-field input:is(:focus, :valid) ~ i {
    color: var(--clr-primary);
  }

  .input-field i.icon {
    left: 0;
  }

  .input-field i.showHidePw {
    right: 0;
    cursor: pointer;
    padding: 10px;
  }

  .form .checkbox-text {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .checkbox-text .checkbox-content {
    display: flex;
    align-items: center;
  }

  .checkbox-content input {
    margin: 0 8px -2px 4px;
    accent-color: var(--clr-primary);
  }

  .form .text {
    color: #333;
    font-size: 14px;
  }

  .form a.text {
    color: var(--clr-primary);
    text-decoration: none;
  }

  .form a {
    text-decoration: none;
  }

  .form a:hover {
    text-decoration: underline;
  }

  .form .button {
    margin-top: 35px;
  }

  .form .button input {
    border: none;
    color: white;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 6px;
    background-color: var(--clr-primary);
    cursor: pointer;
    transition: all 0.6s ease;
  }

  .button input:hover {
    background-color: var(--clr-fourth);
  }

  .form .login-signup {
    margin-top: 30px;
    text-align: center;
  }
  p {
    margin: 0.5em 0.5em 0 0;
    line-height: 1.4285em;
  }
`;

export const BlogSctionStyle = styled.div`
  .container {
    width: 90%;
    margin: 5% auto;
    height: 100%;
  }
  .loading-loader {
    text-align: center;
  }
  .card {
    display: flex;
    flex-direction: row;
    border: 2px solid #808080ab;
    margin-top: 3%;
  }

  .card img {
    width: 430px;
    height: 300px;
    object-fit: cover;
  }
  .text {
    padding: 2%;
  }
  .text h1 {
    padding-bottom: 1%;
  }
  .text p {
    padding-bottom: 1%;
    color: gray;
  }
  .text h4 {
    font-weight: 400;
    font-size: 0.95rem;
  }
  .button a {
    background-color: #0082e6;
    width: 13%;
    padding: 0.5rem;
    margin-top: 2%;
    border-radius: 3%;
    color: white;
    text-decoration: none;
  }

  @media (max-width: 800px) {
    .card {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    .card img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
    .text {
      padding: 2%;
    }
    .button {
      background-color: #0082e6;
      width: 30%;
      margin: auto;
      padding: 2% 4%;
      margin-top: 3%;
    }
  }
`;

export const BlogDetailSectionStyle = styled.div`
  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 60vh;
      object-fit: cover;
      margin: auto 2rem;
      justify-content: center;
    }
  }
  .text {
    width: 70%;
    margin: 4% auto;
    text-align: center;
    text-align: center;
    /* border: 2px solid lightgray; */
    padding: 3%;
  }
  .text h1 {
    padding-bottom: 1%;
  }
  .text p {
    font-weight: 200;
    color: gray;
    padding-bottom: 1%;
  }
  .text h4 {
    font-weight: 400;
    font-size: 1.1rem;
  }
  @media (max-width: 799px) {
    .text h4 {
      font-size: 0.95rem;
    }
    .text {
      width: 0.9rem;
    }
  }
`;

export const ProfileSectionStyle = styled.div`
  .container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    flex-direction: row;
    width: 80%;
    margin: 5% auto;
  }
  .image {
    margin-right: 4%;
  }
  .container img {
    width: 300px;
    height: auto;
    object-fit: cover;
  }
  .text-profile h2 h3 {
    padding: 2%;
  }
  .text-profile h2 h3 {
    padding: 0 2%;
  }
  .text-profile b {
    color: #0082e6;
  }
  @media (max-width: 800px) {
    .container img {
      width: 260px;
      height: 260px;
    }
    .container {
      display: flex;
      flex-direction: column;
    }
  }
  .text-profile h2 {
    padding: 2%;
  }
  .text-profile p {
    font-size: 1.05rem;
    font-weight: 300;
    // padding: 0 2%;
  }
  .text-profile h3 {
    padding: 0 2%;
  }
  .feedback {
    width: 80%;
    margin: auto;
  }
  .feedback textarea {
    width: 100%;
    color: black;
    font-size: 1.2rem;
    margin-bottom: 2%;
    padding-bottom: 2%;
  }
  .feedback h2 {
    padding: 2%;
    text-align: center;
    font-size: 1.5rem;
  }
  .button a {
    background-color: #0082e6;
    margin: 2%;
    padding: 1% 3%;
    color: white;
    text-decoration: none;
    border-radius: 4%;
  }
  .button {
    margin-top: 3%;
  }
  @media (max-width: 500px) {
    .container img {
      width: 300px;
      height: auto;
      object-fit: cover;
    }
  }
`;

export const BenefitSectionStyle = styled.div`
  margin: 3rem 5rem;

  .header {
    min-height: 4rem;
    width: 100%;
    color: var(--clr-third);
    background-position: center;
    background-size: cover;
    position: relative;
    position: relative;
    opacity: 4;
    z-index: 6;
    background-attachment: fixed;
  }

  .handshake1 i {
    font-size: 50px;
    color: #0082e6;
  }

  .textcard {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1.4rem;
    text-align: center;
    justify-content: center;
  }

  .singlecard {
    margin: 3%;
    padding: 2%;
  }

  .textcard img {
    width: 50%;
    object-fit: cover;
    height: auto;
    margin-bottom: 1%;
  }

  .textcard h2 {
    margin-top: 2%;
    font-weight: 500;
  }
  .textcard p {
    font-weight: 200;
    margin-top: 2%;
  }

  .button {
    padding: 0.5% 3%;
    margin-left: 1%;
    background-color: #0082e6;
    border: none;
    border-radius: 2%;
    color: white;
    font-size: 100%;
  }
  .button a {
  }
`;

export const TestProfileListStyle = styled.div`
  ::-webkit-scrollbar {
    background: #e4e4e4 !important;
    width: 0.5rem;
  }
  ::-webkit-scrollbar-thumb {
    background: #7e7e7e !important;
    border-radius: 1rem;
  }
  .not-found {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .not-found .not-found-img {
    height: 300px;
    width: auto;
  }

  .loading-loader {
    text-align: center;
    margin-top: 8rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  /* * {
  font-family: iransans;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
} */
  .container {
    background: rgba(0, 153, 217, 0.1);
  }

  /* pagination start*/

  @keyframes cdp-in {
    from {
      transform: scale(1.5);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  .cdp {
    position: relative;
    text-align: center;
    padding: 20px 0;
    font-size: 0;
    z-index: 6;
    margin: 50px 0;
    animation: cdp-in 500ms ease both;
    animation-timeout: 200ms;

    &_i {
      font-size: 14px;
      text-decoration: none;

      transition: background 250ms;

      display: inline-block;
      text-transform: uppercase;
      margin: 0 3px 6px;
      height: 38px;
      min-width: 38px;
      border-radius: 38px;
      border: 2px solid var(--clr-primary);
      line-height: 38px;
      padding: 0;
      color: var(--clr-primary);
      font-weight: 700;
      letter-spacing: 0.03em;
      display: none;

      &:first-child,
      &:last-child {
        padding: 0 16px;
        margin: 0 12px 6px;
      }

      &:last-child,
      &:nth-child(2),
      &:nth-last-child(2) {
        display: inline-block;
      }
    }

    &_i:hover {
      background-color: #000;
      color: #fff;
    }

    &:not([actpage="1"]) &_i:nth-child(1) {
      display: inline-block;
    }
  }
  @for $i from 1 through 80 {
    .cdp[actpage="#{$i}"] {
      // 3 before
      .cdp_i:nth-child(#{$i - 2}):not(:first-child):not(:nth-child(2)) {
        display: inline-block;
        pointer-events: none;
        color: transparent;
        border-color: transparent;
        width: 50px;
        &:after {
          content: "...";
          color: #fff;
          font-size: 32px;
          margin-left: -6px;
        }
      }
      // 2 before
      .cdp_i:nth-child(#{$i - 1}):not(:first-child) {
        display: inline-block;
      }
      // before
      .cdp_i:nth-child(#{$i}):not(:first-child) {
        display: inline-block;
      }
      // active
      .cdp_i:nth-child(#{$i + 1}) {
        background-color: #000;
        color: #fff;
        display: inline-block;

        + .cdp_i:last-child {
          display: block !important;
        }
      }
      // next
      .cdp_i:nth-child(#{$i + 2}):not(:last-child) {
        display: inline-block;
      }
      // 2 next
      .cdp_i:nth-child(#{$i + 3}):not(:last-child) {
        display: inline-block;
      }
      // 3 next
      .cdp_i:nth-child(#{$i + 4}):not(:last-child):not(:nth-last-child(2)) {
        display: inline-block;
        pointer-events: none;
        color: transparent;
        border-color: transparent;
        width: 50px;
        &:after {
          content: "...";
          color: #fff;
          font-size: 32px;
          margin-left: -6px;
        }
      }
    }
  }

  /* pagination end*/

  /* title start */

  .body {
    padding: 3rem 3rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2rem;

    padding-top: 8rem;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  button {
    outline: none;
    border: none;
  }
  .card {
    position: relative;
    max-width: 18.75rem;
    max-height: 25rem;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 2rem;
    overflow: hidden;
  }
  .card-body {
    padding: 1rem 0.5rem;
  }
  .card-footer {
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.4s ease;
  }
  .card:hover img {
    transform: scale(1.2);
  }
  .card-thumb {
    position: relative;
    height: 160px;
    transition: height 0.4s ease;
  }
  .card-thumb a {
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .card:hover .card-thumb {
    height: calc(200px - 4.5rem);
  }
  .card-thumb::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.4s ease;
  }
  .card:hover .card-thumb::after {
    opacity: 0.4;
  }
  .card-date {
    display: flex;
    position: absolute;
    top: -3rem;
    right: 0;
    width: fit-content;
    margin: 1rem;
    padding: 0.25rem 0.75rem;
    background-color: #efefef;
    color: #000;
    border-radius: 2rem;
    transition: top 0.4s ease;
  }
  .card-date :first-child {
    margin-right: 0.25rem;
    font-size: 0.75rem;
  }
  .card-date :last-child {
    font-size: 0.75rem;
  }
  .card-category {
    position: absolute;
    bottom: -1rem;
    right: 0;
    margin: 0 1rem;
    padding: 0.25rem 0.75rem;
    background-color: var(--clr-primary);
    color: #fff;
    border-radius: 2rem;
    z-index: 1;
    transition: right 0.4s ease;
  }
  .card:hover .card-category {
    right: 45%;
  }
  .card-subtitle {
    margin-bottom: 0.75rem;
    color: rgb(164, 164, 164);
  }
  .card-description {
    display: block;
    height: 0;
    padding: 0 0.5em;
    font-size: 0.875rem;
    /* color: rgb(96, 91, 91); */
    color: #666c74;
    opacity: 0;
    transition: height 0.4s ease, opacity 0.2s ease;
  }
  .card:hover .card-description {
    height: 4.5rem;
    opacity: 1;
  }
  .card-footer {
    display: flex;
    justify-content: flex-end;
    background: #fff;
  }
  .card-time,
  .card-comment {
    margin: 0.25rem 1rem 0.25rem;
    color: #ffffff;
    background: var(--clr-primary);
    border: none;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
  }
  .card-time span,
  .card-comment span {
    font-size: 0.75rem;
    line-height: 1.875rem;
    vertical-align: top;
  }
  .card-time {
    margin-right: 0.5rem;
  }
  .card:hover .card-date {
    top: 0;
  }
  .members-pg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;
    padding-bottom: 2rem;
  }
  .truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
