export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADED_SUCCESS = "USER_LOADED_SUCCESS";
export const USER_LOADED_FAIL = "USER_LOADED_FAIL";
export const IS_LOADING='IS_LOADING';
export const PROFILE_CREATE = "PROFILE_CREATE";
export const PROFILE_CREATE_FAIL = "PROFILE_CREATE_FAIL";
export const PROFILE_CREATE_SUCCESS = "PROFILE_CREATE_SUCCESS";


export const PROFILE_LOAD = "PROFILE_LOAD";
export const PROFILE_LOAD_SUCCESS = "PROFILE_LOAD_SUCCESS";
export const PROFILE_LOAD_FAIL = "PROFILE_LOAD_FAIL";
export const USER_PROFILE_LOAD='USER_PROFILE_LOAD';
export const USER_PROFILE_UPDATING='USER_PROFILE_UPDATING'
export const USER_PROFILE_UPDATE_SUCCESSFUL='USER_PROFILE_UPDATE_SUCCESSFUL';
export const REMOVE_USER_PROFILE='REMOVE_USER_PROFILE';
export const SINGLE_PROFILE_LOAD = "SINGLE_PROFILE_LOAD";
export const REMOVE_PROFILE='REMOVE_PROFILE';
export const SINGLE_PROFILE_LOAD_SUCCESS = "SINGLE_PROFILE_LOAD_SUCCESS";
export const SINGLE_PROFILE_LOAD_FAIL = "SINGLE_PROFILE_LOAD_FAIL";
export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";
export const REMOVE_ME = "REMOVE_ME";

export const GET_IS_PAID_LOADED='GET_IS_PAID_LOADED_SUCCESS';
export const SET_IS_PAID='SET_IS_PAID';
export const LOAD_EVENTS = "LOAD_EVENT";
export const LOAD_EVENT = "LOAD_EVEN";
export const LOAD_EVENTS_SUCCESS = "LOAD_EVENT_SUCCESS";
export const LOAD_EVENTS_FAIL = "LOAD_EVENT_FAIL";
export const LOGOUT = "LOGOUT";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_CONFIRM_FAIL = "PASSWORD_RESET_CONFIRM_FAIL";
export const PASSWORD_RESET_CONFIRM_SUCCESS = "PASSWORD_RESET_CONFIRM_SUCCESS";
export const BANEER_LOAD_SUCCESS = "BANEER_LOAD_SUCCESS";
export const BANNER_LOAD_FAIL = "BANNER_LOAD_FAIL";


export const CATEGORY_LOAD = 'CATEGORY_LOAD';
export const CATEGORY_LOAD_SUCCESS = 'CATEGORY_LOAD_SUCCESS';
export const CATEGORY_LOAD_FAIL = 'CATEGORY_LOAD_FAIL';
