import { combineReducers } from "redux";
import auth from "./auth"
import { profileReducer } from "./profileReducer";
import eventReducer from "./eventReducer";
import {bannerReducer} from "./bannerReducer"
import categoryReducer from "./categoryReducer";


export default combineReducers({
    auth,
    profileReducer,
    bannerReducer,
    categoryReducer,
    events: eventReducer,

    // selectedEvent: selectedEventReducer

});

